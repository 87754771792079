<template>
    <v-row>
        <v-col cols="12">
            <v-tabs
                    v-model="activeTab"
                    color="success"
            >
                <v-tab>Rollen</v-tab>
                <v-tab v-for="group in groups" :key="group">{{groupsTranslations[group]}}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="activeTab">
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <roles></roles>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item v-for="group in groups" :key="group">
                    <v-card flat>
                        <v-card-text>
                            <options :options="getOptions(group)"
                                     :group="group"
                            ></options>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>

    import Options from "./Options"
    import {fetchOptions} from './../graphql/option'
    import {constants} from './../graphql/user'

    export default {
        components: {Options},
        apollo: {
            options: {
                query: fetchOptions,
                fetchPolicy: 'network-only'
            },
        },
        mounted: function () {
            this.$apollo.query({
                query: constants,
                fetchPolicy: 'network-only'
            }).then(({data}) => {
                const constants = JSON.parse(data.constants)
                this.groups = constants.option_groups
            })
        },
        methods: {
            getOptions(group) {
                return this.options ? this.options.filter(option => option.group == group) : []
            }
        },
        data() {
            return {
                activeTab: null,
                groups: [],
                loading: true,
                groupsTranslations: {
                    missing: 'Abwesenheiten',
                    state: 'Bundesländer',
                    zone: 'Zonen',
                    holiday: 'Feiertage',
                    tkz: 'Tätigkeitskennzeichen',
                }
            }
        }
    }
</script>