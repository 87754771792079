<template>
    <div>
        <v-row>
            <v-col class="text-right">
                <v-btn @click="openOptionForm(null)" color="primary">HINZUFÜGEN</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                        :headers="headers"
                        :items="options"
                        :server-items-length="options.length"
                        :loading="loading"
                        class="elevation-1"
                        hide-default-footer
                >
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="openOptionForm(item.id, true)" small icon>
                            <v-icon>
                                mdi-eye
                            </v-icon>
                        </v-btn>
                        <v-btn @click="openOptionForm(item.id)" small icon color="success">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn @click="deleteOption(item.id)" small icon color="accent">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>

                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {fetchOptions, removeOption} from './../graphql/option'


    export default {
        name: 'Options',
        props: [
            'options',
            'group',
        ],
        data() {
            return {
                loading: false,
                roles: [],
                headers: [
                    {
                        text: 'Id',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: 'Value',
                        sortable: false,
                        value: 'value',
                    },
                    {
                        text: 'Description',
                        sortable: false,
                        value: 'description',
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'actions',
                        width: '120px'
                    }
                ],
            };
        },
        methods: {
            openOptionForm(id, readonly = false) {
                this.$root.$children[0].$refs['option-form'].open(id, readonly,()=>{
                    this.$apollo.query({
                        query: fetchOptions,
                        fetchPolicy: 'network-only'
                    })
                }, this.group)
            },
            deleteOption(id) {
                this.$root.$children[0].$refs.confirm.open('Delete', 'Sind Sie sicher das Sie entfernen möchten?', {color: 'accent'}).then((confirm) => {
                    if (confirm) {
                        this.$apollo.mutate({
                            mutation: removeOption,
                            variables: {id},
                            update: (store, {data: {removeOption}}) => {
                                this.$apollo.query({
                                    query: fetchOptions,
                                    fetchPolicy: 'network-only'
                                })
                            }
                        }).then(() => {
                            this.$root.$children[0].successMessage()
                        }).catch(()=>{
                            this.$root.$children[0].errorMessage()
                        })
                    }
                })
            },
        }
    }
</script>